<template>
  <div id="mapa-categorizada"></div>
</template>

<script>
import L from 'leaflet';
import PreviewMapaMixins from './PreviewMapaMixins';

export default {
  mixins: [PreviewMapaMixins],

  data() {
    return {
      mapaId: 'mapa-categorizada'
    };
  },

  mounted() {
    this.desenhaMapa();
  },

  computed: {
    campoParaClassificacao() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .campoParaClassificacao;
    },

    iconesCoresFeicoes() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .iconesCoresFeicoes;
    },

    iconeCorOutrasFeicoes() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .iconeCorOutrasFeicoes;
    },

    updateIconeCor() {
      return this.iconesCoresFeicoes, this.iconeCorOutrasFeicoes;
    }
  },

  watch: {
    updateIconeCor: {
      handler() {
        this.desenhaMapa();
      },
      deep: true
    }
  },

  methods: {
    plotaFeicoesMapa() {
      const getMapFeatureRadius = this.getMapFeatureRadius;

      this.linhasRelatorio.forEach((linha) => {
        const dadoParaClassificacao = linha[this.campoParaClassificacao];
        let iconeCor = this.getIconeCorFeicaoByDadoParaClassificacao(
          dadoParaClassificacao
        );

        let layer = L.geoJSON(JSON.parse(linha.geom), {
          style: () => {
            return {
              color: iconeCor.cor,
              fillColor: iconeCor.cor,
              fillOpacity: this.getMapFeatureFillOpacity,
              weight: this.getMapFeatureWeight
            };
          },
          pointToLayer: function (feature, latlng) {
            if (!iconeCor.icone) {
              return L.circleMarker(latlng, {
                radius: getMapFeatureRadius
              });
            } else {
              return L.marker(latlng, {
                icon: L.icon.glyph({
                  iconMDI: iconeCor.icone,
                  colorBackground: iconeCor.cor
                })
              });
            }
          }
        });

        let msgPopup = this.msgPopup(linha);
        if (msgPopup) layer.bindPopup(msgPopup);

        layer.addTo(this.map);
      });
    },

    getIconeCorFeicaoByDadoParaClassificacao(dadoParaClassificacao) {
      if (typeof dadoParaClassificacao === 'string') {
        return (
          this.iconesCoresFeicoes[dadoParaClassificacao.trim()] ||
          this.iconeCorOutrasFeicoes
        );
      }

      return this.iconeCorOutrasFeicoes;
    },

    criaLegenda() {
      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create(
          'div',
          'mapa-categorizada-info mapa-categorizada-legend'
        );

        if (this.campoParaClassificacao) {
          div.innerHTML += `<h4>${this.campoParaClassificacao}</h4>`;
        }

        for (let dado in this.iconesCoresFeicoes) {
          let iconeCor = this.getIconeCorFeicaoByDadoParaClassificacao(dado);
          let bgColor1 = iconeCor.icone ? iconeCor.cor : 'white';
          let bgColor2 = iconeCor.icone ? 'white' : iconeCor.cor;

          div.innerHTML += `
              <div style="display:flex; align-items: center; margin: 3px 0px;">
                <span style="background-color: ${bgColor1}">
                  <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${iconeCor.cor}">
                    <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeCor.icone}"></i>
                  </div>
                </span>&nbsp;${dado}
              </div>
            `;
        }

        let bgColor1 = this.iconeCorOutrasFeicoes.icone
          ? this.iconeCorOutrasFeicoes.cor
          : 'white';
        let bgColor2 = this.iconeCorOutrasFeicoes.icone
          ? 'white'
          : this.iconeCorOutrasFeicoes.cor;

        div.innerHTML += `
            <div style="display:flex; align-items: center">
              <span style="background-color: ${bgColor1}">
                <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${this.iconeCorOutrasFeicoes.cor}">
                  <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${this.iconeCorOutrasFeicoes.icone}"></i>
                </div>
              </span>&nbsp;Outros
            </div>
          `;

        return div;
      };

      legend.addTo(this.map);
    }
  }
};
</script>

<style>
#mapa-categorizada {
  min-height: 330px;
  max-height: 330px;
  z-index: 0;
}

.mapa-categorizada-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.mapa-categorizada-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mapa-categorizada-legend {
  line-height: 18px;
  color: #555;
  max-height: 177px !important;
  overflow-y: auto !important;
}

.mapa-categorizada-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
